<template>
<template v-if="isAccess">
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				
				<div class="flex justify-content-between mb-1 text-xl">
					<div class="flex justify-content-between">
						<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:4rem;height:4rem">
							<i class="pi pi-shopping-cart text-blue-600" style="font-size:2rem;"></i>
						</div>
						<div class="ml-3">
							<span class="block text-900 font-medium mb-2">今日订单数</span>
							<span class="block text-500 font-medium">昨日订单数</span>
						</div>
					</div>
					<div>
							<span v-if="topdata.todayOrderCount!=null" class="block text-900 font-medium mb-2">{{topdata.todayOrderCount}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
							<span v-if="topdata.yestodayOrderCount!=null" class="block text-500 font-medium">{{topdata.yestodayOrderCount}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
					</div>
				</div>

			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-1 text-xl">
					<div class="flex justify-content-between">
						<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:4rem;height:4rem">
							<i class="pi pi-dollar text-orange-600" style="font-size:2rem;"></i>
						</div>
						<div class="ml-3">
							<span class="block text-900 font-medium mb-2">今日销售额</span>
							<span class="block text-500 font-medium">昨日销售额</span>
						</div>
					</div>
					<div>
							<span v-if="topdata.todaySales!=null" class="block text-900 font-medium mb-2">{{formatCurrency(topdata.todaySales)}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
							<span v-if="topdata.yestodaySales!=null" class="block text-500 font-medium">{{formatCurrency(topdata.yestodaySales)}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-1 text-xl">
					<div class="flex justify-content-between">
						<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:4rem;height:4rem">
							<i class="pi pi-shopping-bag text-cyan-600" style="font-size:2rem;"></i>
						</div>
						<div class="ml-3">
							<span class="block text-900 font-medium mb-2">今日销量</span>
							<span class="block text-500 font-medium">昨日销量</span>
						</div>
					</div>
					<div>
							<span v-if="topdata.todayItemCount!=null" class="block text-900 font-medium mb-2">{{topdata.todayItemCount}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
							<span v-if="topdata.yestodayItemCount!=null" class="block text-500 font-medium">{{topdata.yestodayItemCount}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-1 text-xl">
					<div class="flex justify-content-between">
						<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:4rem;height:4rem">
							<i class="pi pi-money-bill text-purple-600" style="font-size:2rem;"></i>
						</div>
						<div class="ml-3">
							<span class="block text-900 font-medium mb-2">今日广告费</span>
							<span class="block text-500 font-medium">昨日广告费</span>
						</div>
					</div>
					<div>
							<span v-if="topdata.todayAdCost!=null" class="block text-900 font-medium mb-2">{{formatCurrency(topdata.todayAdCost)}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
							<span v-if="topdata.yestodayAdCost!=null" class="block text-500 font-medium">{{formatCurrency(topdata.yestodayAdCost)}}</span>
							<span v-else class="block text-900 font-medium mb-2"><i class="pi pi-spin pi-spinner"></i></span>
					</div>
				</div>
				<!-- <div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-900 font-medium mb-3">Comments</span>
						<div class="text-900 font-medium text-xl">152 Unread</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-comment text-purple-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">85 </span>
				<span class="text-500">responded</span> -->
			</div>
		</div>
		<div class="col-12">
			<div class="card mb-0">
				<div class="flex justify-content-between align-items-center">
					<h5>销量一览</h5>
					<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div>
				</div>
			<Chart type="line" :eight="100" :data="lineData" :options="lineOptions" />
		</div>
		</div>

	<div class="col-12 xl:col-6">
		<div class="card mb-0">
			<h5>买家消息</h5>
			<span>待回复(5)</span>
			<!-- <Button type="button" label="测试按钮" @click="test" />
			<Button class="ml-2" type="button" label="测试按钮1" @click="test1" /> -->
		</div>
		<!-- <div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>Best Selling Products</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div>
			</div>
			<ul class="list-none p-0 m-0">
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Space T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-orange-500 h-full" style="width:50%"></div>
						</div>
						<span class="text-orange-500 ml-3 font-medium">%50</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Portal Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-cyan-500 h-full" style="width:16%"></div>
						</div>
						<span class="text-cyan-500 ml-3 font-medium">%16</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Supernova Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-pink-500 h-full" style="width:67%"></div>
						</div>
						<span class="text-pink-500 ml-3 font-medium">%67</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Wonders Notebook</span>
						<div class="mt-1 text-600">Office</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-green-500 h-full" style="width:35%"></div>
						</div>
						<span class="text-green-500 ml-3 font-medium">%35</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Mat Black Case</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-purple-500 h-full" style="width:75%"></div>
						</div>
						<span class="text-purple-500 ml-3 font-medium">%75</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Robots T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-teal-500 h-full" style="width:40%"></div>
						</div>
						<span class="text-teal-500 ml-3 font-medium">%40</span>
					</div>
				</li>
			</ul>
		</div> -->
	</div>
	<div class="col-12 xl:col-6">
		<div class="card mb-0">
			<h5>通知</h5>
			<span>新通知(19)</span>
			<!-- <ul class="p-0 mx-0 mt-0 mb-4 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Richard Jones
						<span class="text-700">has purchased a blue t-shirt for <span class="text-blue-500">79$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-download text-xl text-orange-500"></i>
					</div>
					<span class="text-700 line-height-3">Your request for withdrawal of <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span>
				</li>
			</ul>

			<span class="block text-600 font-medium mb-3">YESTERDAY</span>
			<ul class="p-0 m-0 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Keyser Wick
						<span class="text-700">has purchased a black jacket for <span class="text-blue-500">59$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-question text-xl text-pink-500"></i>
					</div>
					<span class="text-900 line-height-3">Jane Davis
						<span class="text-700">has posted a new questions about your product.</span>
					</span>
				</li>
			</ul> -->
		</div>
	</div>
	</div>
</template>
<template v-else>
	<Access />
</template>
</template>

<script>
import Access from '../components/Access.vue';
import Service from '../service/Service';

export default {
	data() {
		return {
			user:null,
			isAccess:true,
			products: null,
			topdata:{
				todayOrderCount:null,
				yestodayOrderCount:null,
				todaySales:null,
				yestodaySales:null,
				todayItemCount:null,
				yestodayItemCount:null,
				todayAdCost:null,
				yestodayAdCost:null
			},
			lineData: {
				labels: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00','8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'],
				datasets: [
					{
						label: '今日',
						data: new Array(24),
						fill: false,
						backgroundColor: '#337ab7',
						borderColor: '#337ab7',
						tension: 0
					},
					{
						label: '昨日',
						data: new Array(24),
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e',
						tension: 0
					},
				]
			},
			items: [
                {label: '测试按钮', icon: 'pi pi-fw pi-plus',command:()=>{this.test();}},
                {label: '测试按钮1', icon: 'pi pi-fw pi-minus',command:()=>{this.test1();}}
            ],
			lineOptions: null,
		}
	},
	Service: null,
	mounted() {
		this.user=JSON.parse(localStorage.getItem('user'));
		this.getTopData();

	},
	created() {
		this.Service = new Service();
	},
	components: {
		'Access': Access
	},
	methods: {
		getTopData(){
			var daylight={};
			daylight.dateStart=localStorage.getItem('dateStart');
			daylight.dateEnd=localStorage.getItem('dateEnd');
			var date=new Date();
			if(daylight.dateStart<=date.getTime()&&date.getTime()<daylight.dateEnd){
				date.setTime(date.valueOf()-15*60*60*1000);
			}
			else{
				date.setTime(date.valueOf()-16*60*60*1000);
			}
			//今日广告费
			this.Service.sendHttp('index/getadcost.php?date='+this.formatDate(date)).then(res=>res.json()).then(data=>{
				if(data.result=='success'){
					this.topdata.todayAdCost=data.adCost;
				}
			});
			date.setTime(date.valueOf()-24*60*60*1000);
			//昨日广告费
			this.Service.sendHttp('index/getadcost.php?date='+this.formatDate(date)).then(res=>res.json()).then(data=>{
				if(data.result=='success'){
					this.topdata.yestodayAdCost=data.adCost;
				}
			});
			//图表数据
			this.Service.sendHttp('index/getSales.php?date='+this.formatDate(date)).then(res=>res.json()).then(data=>{
				if(data.result=='success'){
					var sales=[];
					data.total.forEach(storeSale=>{
						storeSale.forEach((sale,index)=>{
							if(!sales[index]){
								sales[index]={};
								sales[index].interval=sale.interval;
								sales[index].unitCount=parseInt(sale.unitCount);
								sales[index].orderItemCount= parseInt(sale.orderItemCount);
								sales[index].orderCount= parseInt(sale.orderCount);
								sales[index].averageUnitPrice=parseFloat(sale.averageUnitPrice);
								sales[index].totalSales=parseFloat( sale.totalSales);
							}
							else{
								sales[index].unitCount+=parseInt(sale.unitCount);
								sales[index].orderItemCount+= parseInt(sale.orderItemCount);
								sales[index].orderCount+= parseInt(sale.orderCount);
								sales[index].averageUnitPrice+=parseFloat(sale.averageUnitPrice);
								sales[index].totalSales+=parseFloat(sale.totalSales);
							}
						});
					});
					this.topdata.todayItemCount=0;
					this.topdata.todayOrderCount=0;
					this.topdata.todaySales=0;
					this.topdata.yestodayItemCount=0;
					this.topdata.yestodayOrderCount=0;
					this.topdata.yestodaySales=0;
					
					sales.forEach((sale,index)=>{
						if(index<24){
							this.topdata.yestodayItemCount+=sale.orderItemCount;
							this.topdata.yestodayOrderCount+=sale.orderCount;
							this.topdata.yestodaySales+=sale.totalSales;
							this.lineData.datasets[1].data[index]=sale.unitCount;
						}
						else{
							this.topdata.todayItemCount+=sale.orderItemCount;
							this.topdata.todayOrderCount+=sale.orderCount;
							this.topdata.todaySales+=sale.totalSales;
							this.lineData.datasets[0].data[index-24]=sale.unitCount;
						}
						
					});
					//console.log(this.topdata);
				}
			});
		},
		test(){
			//this.Service.httpGet();
			this.Service.sendHttp('index/getadcost.php?date=2022-11-30').then(res=>res.text()).then(data=>{
				console.log(data);
			});
		},
		test1(){
			fetch('https://mizongkeji.xyz/google/test1.php?token=222',).then(res => res.text()).then(d => alert(d));
		},
		formatCurrency(value) {
			if(value!=null)
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			else
			return value;
		},
		formatDate(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        },
	}
}
</script>